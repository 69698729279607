import React from 'react';
import _ from 'lodash';

import {htmlToReact, withPrefix, markdownify} from '../utils';
import CtaButtons from './CtaButtons';

export default class SectionFeatures extends React.Component {
    renderVideo(feature) {
      const width = 640
      const height = 400
      const source = <source src={feature.video} type="video/mp4"></source>
      return  <div className='video ondemand'>
        {/* eslint-disable jsx-a11y/media-has-caption */}
        <video widt={width} height={height} poster={feature.poster}>{source}</video></div>
    }
    render() {
        let section = _.get(this.props, 'section', null);
        return (
            <section id={_.get(section, 'section_id', null)} className={'block features-block bg-' + _.get(section, 'background', null) + ' outer'}>
              <div className="block-header inner-small">
                {_.get(section, 'title', null) && (
                <h2 className="block-title">{_.get(section, 'title', null)}</h2>
                )}
                {_.get(section, 'subtitle', null) && (
                <p className="block-subtitle">
                  {htmlToReact(_.get(section, 'subtitle', null))}
                </p>
                )}
              </div>
              {_.get(section, 'features', null) && (
              <div className="inner">
                {_.map(_.get(section, 'features', null), (feature, feature_idx) => (
                <div key={feature_idx} className={`block-item ${feature.dir}`}>
                  <div className="grid">
                    {(feature.image || feature.video) && (
                    <div className={`cell block-preview ${(feature.title || feature.content || feature.actions)?'':'no-content'}`}>
                      {
                        feature.image && feature.image.split(",").length === 1 &&
                        <img src={withPrefix(_.get(feature, 'image', null))} alt={_.get(feature, 'title', null)} />
                      }
                      {
                        feature.image && feature.image.split(",").length > 1 &&
                        feature.image.split(",").map(i => <img key={`${i}`} src={i} alt=""></img>)
                      }
                      {
                        feature.video && !feature.video.includes("youtube.com") &&
                        this.renderVideo(feature)                        
                      }
                      {
                        feature.video && feature.video.includes("youtube.com") &&
                        <iframe title="video" width="560" height="315" src={feature.video} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      }

                    </div>
                    )}
                    {
                      (feature.title || feature.content || feature.actions) && 
                      <div className="cell block-content">
                        <h3 className="block-title underline">{_.get(feature, 'title', null)}</h3>
                        <div className="block-copy">
                          {markdownify(_.get(feature, 'content', null))}
                        </div>
                        {_.get(feature, 'actions', null) && (
                        <p className="block-buttons">
                          <CtaButtons {...this.props} actions={_.get(feature, 'actions', null)} />
                        </p>
                        )}
                      </div>
                    }
                  </div>
                </div>
                ))}
                </div>
              )}
            </section>
        );
    }
}
