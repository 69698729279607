import React from 'react';
import {Helmet} from 'react-helmet';
import _ from 'lodash';

import '../sass/main.scss';
import Header from './Header';
import Footer from './Footer';
import { langContext, LangContextProvider } from '../context/LangContext'

export default class Body extends React.Component {

    static contextType = langContext


    render() {

        return (
            <LangContextProvider {...this.props}>
                <Helmet>
                    <title>{_.get(this.props, 'pageContext.site.siteMetadata.title', null)} | {_.get(this.props, 'pageContext.frontmatter.title', null) && (_.get(this.props, 'pageContext.frontmatter.title', null))}</title>
                    <meta charSet="utf-8"/>
                    <meta name="viewport" content="width=device-width, initialScale=1.0" />
                    <meta name="google" content="notranslate" />
                    <meta name="description" content=" Manage all your PTC sites in less time. Available for Google Chrome, Firefox and many other browsers. " />
                    <meta name="author" content="Buxenger" />
                    <meta property="og:title" content="Buxenger" />
                    <link rel="icon" type="image/png" href="images/favicon.png" />
                    <link href="https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,700i" rel="stylesheet"/>
                </Helmet>
                <div id="page" className={`site palette-${this.props.pageContext.site.siteMetadata.palette[this.context]}`}>
                  <Header {...this.props} />
                  <main id="content" className="site-content">
                    {this.props.children}
                  </main>
                  <Footer {...this.props} />
                </div>
            </LangContextProvider>
        );
    }
}
