import React from 'react';
import _ from 'lodash';

import {htmlToReact, markdownify} from '../utils';
import { Helmet } from 'react-helmet';
import { withPrefix } from "gatsby";
import { langContext } from '../context/LangContext';

export default class SectionContact extends React.Component {

    static contextType = langContext

    render() {
        let section = _.get(this.props, 'section', null);
        return (
            <section id={_.get(section, 'section_id', null)} className={'block contact-block bg-' + _.get(section, 'background', null) + ' outer'}>
              <div className="block-header inner-small">
                {_.get(section, 'title', null) && (
                <h2 className="block-title">{_.get(section, 'title', null)}</h2>
                )}
                {_.get(section, 'subtitle', null) && (
                <p className="block-subtitle">
                  {htmlToReact(_.get(section, 'subtitle', null))}
                </p>
                )}
              </div>
              <div className="block-content inner-medium">
                {markdownify(_.get(section, 'content', null))}
                <form name="zsWebToCase_67715000000047080" method="POST" 
                  className="contact-form" action='https://support.zoho.com/support/WebToCase'>
                     <input type='hidden' name='xnQsjsdp' value='JN*KPKhXdstthYo*kRl79w$$' />
                     <input type='hidden' name='xmIwtLD' value='I4UqQxVDAeTS1NCxX3-J1*BmZMcd8Yr7' />
                     <input type='hidden' name='xJdfEaS' value='' />
                     <input type='hidden' name='actionType' value='Q2FzZXM=' />
                     <input type="hidden" id="property(module)" value="Cases" />
                     <input type="hidden" id="dependent_field_values_Cases" value="&#x7b;&quot;JSON_VALUES&quot;&#x3a;&#x7b;&quot;Category&quot;&#x3a;&#x7b;&quot;Sub&#x20;Category&quot;&#x3a;&#x7b;&quot;General&quot;&#x3a;&#x5b;&quot;Sub&#x20;General&quot;&#x5d;,&quot;Defects&quot;&#x3a;&#x5b;&quot;Sub&#x20;Defects&quot;&#x5d;&#x7d;&#x7d;&#x7d;,&quot;JSON_SELECT_VALUES&quot;&#x3a;&#x7b;&quot;Category&quot;&#x3a;&#x5b;&quot;General&quot;,&quot;Defects&quot;&#x5d;,&quot;Sub&#x20;Category&quot;&#x3a;&#x5b;&quot;Sub&#x20;General&quot;,&quot;Sub&#x20;Defects&quot;&#x5d;&#x7d;,&quot;JSON_MAP_DEP_LABELS&quot;&#x3a;&#x5b;&quot;Category&quot;&#x5d;&#x7d;" />
                     <input type='hidden' name='returnURL' value={`https://www.buxenger.com/${this.context === 'en'?'':this.context +'/'}contact/thank-you/`} />
                     <input type='hidden' name='Status' value='Open' />
                  
                  <p className="form-row">
                    <label id="contact-form-name-label" htmlFor="contact-form-name" className="form-label">{section.label_name}</label>
                    <input type="text" name="Contact Name" id="contact-form-name" aria-labelledby="contact-form-name-label" className="form-input"/>
                  </p>
                  <p className="form-row">
                    <label id="contact-form-email-label" htmlFor="contact-form-email" className="form-label">{section.label_email}</label>
                    <input type="email" name="Email" id="contact-form-email" aria-labelledby="contact-form-email-label" className="form-input"/>
                  </p>
                  <p className="form-row">
                    <label id="contact-form-subject-label" htmlFor="contact-form-subject" className="form-label">{section.label_subject}</label>
                    <input type="text" name="Subject" id="contact-form-subject" aria-labelledby="contact-form-subject-label" className="form-input"/>
                  </p>
                  <p className="form-row">
                    <label id="contact-form-message-label" htmlFor="contact-form-message" className="form-label">{section.label_message}</label>
                    <textarea name="Description" id="contact-form-message" aria-labelledby="contact-form-message-label" className="form-textarea" rows="7" />
                  </p>
                  <p className="form-row">
                  <div><img src='#' id='zsCaptchaUrl' name="zsCaptchaImage" style={{ cursor: 'pointer'}} /></div>
                  <div><input type='text' name='zsWebFormCaptchaWord' class="form-control input-lg" /><input type='hidden' name='zsCaptchaSrc' value='' /></div>
  
                  </p>
                  <p className="form-row form-submit">
                    <button type="submit" className="button" id="zsSubmitButton_67715000000047080">{section.label_send}</button>
                  </p>
                </form>
              </div>
              <Helmet>
              <script
  src="https://code.jquery.com/jquery-3.5.1.min.js"
  integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
  crossorigin="anonymous"></script>
                <script src={withPrefix('js/contact.js')} type="text/javascript" /></Helmet>

            </section>
        );
    }
}
